import { getPublicConfig } from '../configs/app'
import { StrapiArticle, StrapiArticleLight, StrapiAsset, StrapiAssetArticle, StrapiAssetArticleLight, StrapiAssetLight, StrapiAssetPage, StrapiCategory, StrapiFundamentalAnalysis, StrapiTag } from 'libs/api/client/types'
import { AssetArticle, Asset, Category, Article, AssetPage, Tag, FundamentalAnalysis } from 'types'

export const convertAssetToStrapiAsset = (asset: Asset): StrapiAsset => {
  return {
    id: asset.id,
    attributes: {
      code: asset.code,
      name: asset.name,
      logo: {
        data: {
          id: 1,
          attributes: {
            name: asset.name,
            url: typeof asset.logo === 'string' ? asset.logo : '',
          },
        },
      },
      categories: {
        data: asset.categories.map(c => {
          return {
            attributes: {
              name: c.name,
              category_id: c.category_id,
              description: c.description,
              title: c.title
            },
          }
        }),
      },
      asset_page: {
        data: {
          id: 1,
          attributes: {
            content: asset.article?.content || null,
            description: asset.article?.description || null,
            locale: asset.article?.locale || null,
          },
        },
      },
      cg_api_id: asset.cg_api_id,
      links: asset.links,
      contracts: asset.contracts,
      price: asset.price,
      price_change_1h: asset.price_change_1h,
      price_change_24h: asset.price_change_24h,
      price_change_7d: asset.price_change_7d,
      market_cap: asset.market_cap,
      circulation_supply: asset.circulation_supply,
      total_supply: asset.total_supply,
      max_supply: asset.max_supply,
      volume_24h: asset.volume_24h,
      locale: 'en', // FIXME
      updatedAt: null,
    },
  }
}

export const convertAssetFromStrapiAsset = (strapiAsset: StrapiAsset): Asset => {
  const attributes = strapiAsset.attributes
  const strapiUrl = getPublicConfig().strapiUrl
  const logoUrl = attributes?.logo?.data?.attributes?.url

  return {
    id: strapiAsset?.id,
    code: attributes?.code,
    name: attributes?.name,
    logo: logoUrl ? `${strapiUrl}${logoUrl}` : null,
    logo_url: logoUrl ? `${strapiUrl}${logoUrl}` : null,
    logoId: attributes?.logo?.data?.id ?? null,
    article: {
      content: attributes?.asset_page?.data?.attributes?.content || null,
      description: attributes?.asset_page?.data?.attributes?.description || null,
      locale: attributes?.asset_page?.data?.attributes?.locale || null
    },
    categories: attributes?.categories?.data.map(c => ({
      name: c.attributes.category_id,
      category: c.attributes.name,
      category_id: c.attributes.category_id,
      description: c.attributes.description,
      title: c.attributes.title,
      locale: c.attributes.locale || null,
      localizations: c.attributes.localizations?.data.map(l => convertCategoryFromStrapiCategory(l)) ?? [],
    } as Category)) ?? [],
    cg_api_id: attributes?.cg_api_id ?? null,
    links: attributes?.links ?? [],
    contracts: attributes?.contracts ?? [],
    price: attributes?.price ?? null,
    price_change_1h: attributes?.price_change_1h ?? null,
    price_change_24h: attributes?.price_change_24h ?? null,
    price_change_7d: attributes?.price_change_7d ?? null,
    market_cap: attributes?.market_cap ?? null,
    circulation_supply: attributes?.circulation_supply ?? null,
    total_supply: attributes?.total_supply ?? null,
    max_supply: attributes?.max_supply ?? null,
    volume_24h: attributes?.volume_24h ?? null,
  }
}

export const convertAssetFromStrapiAssetWithLocalizedAssetPage = (strapiAsset: StrapiAsset, assetPageLocale?: string): Asset => {
  const attributes = strapiAsset.attributes
  const strapiUrl = getPublicConfig().strapiUrl
  const logoUrl = attributes?.logo?.data?.attributes?.url

  let localAssetPage = null

  if (assetPageLocale) {
    localAssetPage = attributes?.asset_page?.data?.attributes.localizations?.data?.find((page) => page.attributes.locale === assetPageLocale)
  }

  return {
    id: strapiAsset?.id,
    code: attributes?.code,
    name: attributes?.name,
    logo: logoUrl ? `${strapiUrl}${logoUrl}` : null,
    logo_url: logoUrl ? `${strapiUrl}${logoUrl}` : null,
    logoId: attributes?.logo?.data?.id ?? null,
    article: assetPageLocale && localAssetPage ? {
      content: localAssetPage.attributes.content || null,
      description: localAssetPage.attributes.description || null,
      locale: localAssetPage.attributes.locale || null
    } : {
      content: attributes?.asset_page?.data?.attributes?.content || null,
      description: attributes?.asset_page?.data?.attributes?.description || null,
      locale: attributes?.asset_page?.data?.attributes?.locale || null
    },
    categories: attributes?.categories?.data.map(c => ({
      name: c.attributes.category_id,
      category: c.attributes.name,
      category_id: c.attributes.category_id,
      description: c.attributes.description,
      title: c.attributes.title,
      locale: c.attributes.locale || null,
      localizations: c.attributes.localizations?.data.map(l => convertCategoryFromStrapiCategory(l)) ?? [],
    } as Category)) ?? [],
    cg_api_id: attributes?.cg_api_id ?? null,
    links: attributes?.links ?? [],
    contracts: attributes?.contracts ?? [],
    price: attributes?.price ?? null,
    price_change_1h: attributes?.price_change_1h ?? null,
    price_change_24h: attributes?.price_change_24h ?? null,
    price_change_7d: attributes?.price_change_7d ?? null,
    market_cap: attributes?.market_cap ?? null,
    circulation_supply: attributes?.circulation_supply ?? null,
    total_supply: attributes?.total_supply ?? null,
    max_supply: attributes?.max_supply ?? null,
    volume_24h: attributes?.volume_24h ?? null,
  }
}

export const convertAssetPageFromStrapiAssetPage = (strapiAssetPage: StrapiAssetPage): AssetPage => {
  const attributes = strapiAssetPage?.attributes

  return {
    id: strapiAssetPage?.id,
    content: attributes?.content,
    description: attributes?.description,
    locale: attributes?.locale,
  }
}

export const convertArticleToStrapiArticle = (article: AssetArticle): StrapiArticle => {
  return {
    id: article.id,
    attributes: {
      title: article.title,
      slug: article.slug,
      content: article.content,
      description: article.description,
      author: article.author,
      type: article.type,
      image: {
        data: {
          attributes: {
            url: article.image_url,
          },
        },
      },
      asset: article.asset ? { data: convertAssetToStrapiAsset(article.asset) } : undefined,
      tags: {
        data: article.tags.map(t => ({
          id: t.id,
          attributes: {
            name: t.name,
            slug: t.slug,
            locale: t.locale || undefined,
          },
        })),
      },
      locale: 'en', // FIXME
    },
  }
}

export const convertArticleFromStrapiArticle = (strapiArticle: StrapiArticle): Article => {
  const attributes = strapiArticle.attributes
  const strapiUrl = getPublicConfig().strapiUrl
  const imageUrl = attributes.image?.data?.attributes.url ? `${strapiUrl}${attributes.image.data.attributes.url}` : null

  return {
    id: strapiArticle.id,
    slug: attributes?.slug ?? null,
    content: attributes?.content ?? null,
    title: attributes?.title ?? null,
    author: attributes?.author ?? null,
    description: attributes.description ?? null,
    state: 'published', // FIXME
    image_url: imageUrl ?? null,
    asset: attributes.asset?.data ? convertAssetFromStrapiAsset(attributes.asset.data) : null,
    // tags: attributes.tags?.data.map(t => ({ id: t.id, name: t.attributes.name, slug: t.attributes.slug, locale: t.attributes.locale || null })) ?? [],
    tags: attributes.tags?.data.map(convertTagFromStrapi) ?? [],
    created_at: attributes.createdAt || null,
    updated_at: attributes.updatedAt || null,
    published_at: attributes.publishedAt || null,
    locale: attributes.locale || null,
    localizations: attributes.localizations?.data.map(l => convertArticleFromStrapiArticle(l)) ?? [],
  }
}

export const convertAssetArticleFromStrapi = (strapiArticle: StrapiAssetArticle): AssetArticle => {
  const attributes = strapiArticle.attributes
  const strapiUrl = getPublicConfig().strapiUrl
  const imageUrl = attributes.image?.data?.attributes.url ? `${strapiUrl}${attributes.image.data.attributes.url}` : null
  const asset = attributes.asset?.data

  return {
    id: strapiArticle.id,
    asset: asset ? convertAssetFromStrapiAsset(asset) : null,
    asset_code: attributes.asset?.data?.attributes.code ?? null,
    slug: attributes.slug,
    content: attributes.content,
    title: attributes.title,
    author: attributes.author,
    description: attributes.description,
    state: 'published', // FIXME
    image_url: imageUrl,
    type: attributes.type,
    // tags: attributes.tags?.data.map(t => ({ id: t.id, name: t.attributes.name, slug: t.attributes.slug, locale: t.attributes.locale || null })) ?? [],
    tags: attributes.tags?.data.map(convertTagFromStrapi) ?? [],
    created_at: attributes.createdAt || null,
    updated_at: attributes.updatedAt || null,
    published_at: attributes.publishedAt || null,
    locale: attributes.locale || null,
    localizations: attributes.localizations?.data.map(l => convertAssetArticleFromStrapi(l)) ?? [],
  }
}

export const convertCategoryToStrapiCategory = (category: Category): StrapiCategory => {
  return {
    id: category.id,
    attributes: {
      name: category.name,
      category_id: category.category_id,
      description: category.description,
      title: category.title,
      locale: category.locale || undefined,
    },
  }
}

export const convertCategoryFromStrapiCategory = (strapiCategory: StrapiCategory): Category => {
  return {
    id: strapiCategory.id,
    category: strapiCategory.attributes.category_id,
    name: strapiCategory.attributes.name,
    title: strapiCategory.attributes.name,
    category_id: strapiCategory.attributes.category_id,
    description: strapiCategory.attributes.description,
    locale: strapiCategory.attributes.locale || null,
    localizations: strapiCategory.attributes.localizations?.data.map(l => convertCategoryFromStrapiCategory(l)) ?? [],
  }
}

export const convertAssetLightFromStrapi = (asset: StrapiAsset): StrapiAssetLight => {
  return {
    id: asset?.id,
    code: asset?.attributes?.code,
    cg_api_id: asset?.attributes?.cg_api_id,
    updatedAt: asset?.attributes?.updatedAt,
  }
}

export const convertTagFromStrapi = (tag: StrapiTag): Tag => {
  return {
    id: tag.id,
    name: tag.attributes.name,
    slug: tag.attributes.slug,
    locale: tag.attributes.locale || null,
    localizations: tag.attributes.localizations?.data.map(l => convertTagFromStrapi(l)) ?? [],
  }
}

export const convertArticleLightFromStrapi = (article: StrapiArticle): StrapiArticleLight => {
  const attributes = article.attributes
  const asset = attributes.asset?.data
  
  return {
    id: article.id,
    title: attributes.title,
    slug: attributes.slug,
    asset: asset ? convertAssetLightFromStrapi(asset) : null,
    locale: attributes.locale,
    updatedAt: attributes.updatedAt || null,
    localizations: attributes.localizations?.data.map(l => convertArticleLightFromStrapi(l)) ?? [],
  }
}

export const convertAssetArticleLightFromStrapi = (article: StrapiAssetArticle): StrapiAssetArticleLight => {
  const attributes = article.attributes
  const asset = attributes.asset?.data
  
  return {
    id: article.id,
    title: attributes.title,
    slug: attributes.slug,
    type: attributes.type,
    asset: asset ? convertAssetLightFromStrapi(asset) : null,
    locale: attributes.locale,
    updatedAt: attributes.updatedAt || null,
    localizations: attributes.localizations?.data.map(l => convertAssetArticleLightFromStrapi(l)) ?? [],
  }
}

export const convertFundamentalAnalysisFromStrapi = (strapiFA: StrapiFundamentalAnalysis): FundamentalAnalysis => {
  const attributes = strapiFA.attributes
  const strapiUrl = getPublicConfig().strapiUrl
  const imageUrl = attributes.image?.data?.attributes.url ? `${strapiUrl}${attributes.image.data.attributes.url}` : null
  const asset = attributes.asset?.data

  return {
    id: strapiFA.id,
    asset: asset ? convertAssetFromStrapiAsset(asset) : null,
    asset_code: attributes.asset?.data?.attributes.code ?? null,
    slug: attributes.slug,
    content: attributes.content,
    title: attributes.title,
    author: attributes.author,
    description: attributes.description,
    state: 'published',
    image_url: imageUrl,
    created_at: attributes.createdAt || null,
    updated_at: attributes.updatedAt || null,
    published_at: attributes.publishedAt || null,
    locale: attributes.locale || null,
    localizations: attributes.localizations?.data.map(l => convertFundamentalAnalysisFromStrapi(l)) ?? [],
  }
}
