import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FundamentalAnalysis } from 'types';

export interface FundamentalAnalysisState {
    article: FundamentalAnalysis;
    isLoading: boolean;
}

export const EMPTY_FUNDAMENTAL_ANALYSIS: FundamentalAnalysis = {
    id: 0,
    asset_code: null,
    slug: '',
    content: '',
    title: '',
    author: '',
    description: '',
    asset: null,
    state: '',
    image_url: null,
    locale: null,
    localizations: []
}

const initialFundamentalAnalysisState: FundamentalAnalysisState = {
    article: EMPTY_FUNDAMENTAL_ANALYSIS,
    isLoading: false,
};

const fundamentalAnalysisSlice = createSlice({
    name: 'fundamentalAnalyses',
    initialState: initialFundamentalAnalysisState,
    reducers: {
        setFundamentalAnalysis(state, action: PayloadAction<FundamentalAnalysis>) {
            state.article = action.payload;
            state.isLoading = false;
        },
    },
});

export const { setFundamentalAnalysis } = fundamentalAnalysisSlice.actions;
export const fundamentalAnalysisReducer = fundamentalAnalysisSlice.reducer;
export default fundamentalAnalysisSlice.reducer;
