import axios, { AxiosError, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'

export type HTTPMethod = 'get' | 'post' | 'delete' | 'put' | 'patch'

export interface JsonBody {
  // tslint:disable-next-line no-any
  [key: string]: any
}

export interface RequestOptions {
  apiVersion: 'token_listing'
  withHeaders?: boolean
  headers?: object
}

export interface Request {
  method: HTTPMethod
  url: string
  body?: JsonBody
  headers?: {[key: string]: string}
}

const getAPI = () => {
  const hostUrl = process.env.STRAPI_URL || 'http://localhost:1337'

  return {
    tokenListing: `${hostUrl}`,
  }
}

export const buildRequest = (request: Request) => {
  const { body, method, url } = request
  const api = getAPI()

  const headers = request.headers || {}

  headers['content-type'] ||= 'application/json'

  if (process.env.STRAPI_TOKEN) {
    headers['authorization'] = `Bearer ${process.env.STRAPI_TOKEN}`
  }

  // TODO: should be dynamic
  const apiUrl = api['tokenListing']

  const requestConfig: AxiosRequestConfig = {
    baseURL: apiUrl,
    params: method === 'get' ? body : undefined,
    data: method !== 'get' ? body : undefined,
    headers,
    method,
    url,
  }

  return requestConfig
}

export const defaultResponse: Partial<AxiosError['response']> = {
  status: 500,
  data: {
    error: 'Server error',
  },
}

export const formatError = (responseError: AxiosError) => {
  // console.log('responseError', responseError)

  if (responseError.response && responseError.response.status && responseError.response.statusText) {
    return {
      code: responseError.response?.status,
      message: responseError.response?.statusText,
    }
  }

  const response = responseError.response || defaultResponse
  const errors = (response.data && ((response.data as any).errors || [(response.data as any).error])) || []

  return {
    code: response.status,
    message: errors,
  }
}

export const makeRequest = async (request: Request) => {
  const requestConfig = buildRequest(request)

  // console.log('requestConfig', requestConfig)

  return new Promise((resolve, reject) => {
    const axiosRequest: AxiosPromise = axios(requestConfig)

    axiosRequest
      .then((response: AxiosResponse) => {
        // console.log('response', response)
        resolve(response.data)
      })
      .catch((error: AxiosError) => {
        // console.log('error', error)
        reject(formatError(error))
      })
  })
}
