import { convertCategoryFromStrapiCategory } from '../helpers/convertStrapiTypes'
import { API } from '../libs/api/client/api'
import {
  StrapiArticle,
  StrapiArticlesResponse,
  StrapiAssetArticle,
  StrapiAssetArticlesResponse,
  StrapiAssetPage,
  StrapiAssetPagesResponse,
  StrapiAssetsResponse,
  StrapiAssetsUpsetResponse,
  StrapiCategoriesResponse,
  StrapiCategory,
  StrapiFundamentalAnalysesResponse,
  StrapiFundamentalAnalysis,
  StrapiLocale,
  StrapiRedirectionsRegexResponse,
  StrapiRedirectionsResponse,
  StrapiTag,
  StrapiTagsResponse,
} from 'libs/api/client/types'
import { Asset, Category } from 'types'

const EMPTY_STRAPI_RESPONSE = {
  data: [],
  meta: {
    pagination: { page: 1, pageSize: 1, pageCount: 1, total: 0 },
  },
}

export const fetchLocales = async (): Promise<StrapiLocale[]> =>
  await API.get('/api/i18n/locales').catch(e => {
    console.error('Error fetching locales', e)
    return []
  })

export const fetchArticles = async (payload: any = {}): Promise<StrapiArticlesResponse> =>
  await API.get('/api/articles', payload).catch(e => {
    console.error('Error fetching articles', e)
    return EMPTY_STRAPI_RESPONSE
  })

export const insertLocalizedArticle = async (article: StrapiArticle): Promise<StrapiArticlesResponse> =>
  await API.post(`/api/articles/${article.id}/localizations`, { ...article.attributes })

export const insertLocalizedAssetArticle = async (article: any): Promise<StrapiAssetArticlesResponse> =>
  await API.post(`/api/asset-articles/${article.id}/localizations`, { ...article.attributes })

export const insertLocalizedAssetPage = async (article: any): Promise<StrapiAssetPagesResponse> =>
  await API.post(`/api/asset-pages/${article.id}/localizations`, { ...article.attributes })

export const insertLocalizedCategory = async (category: any): Promise<StrapiCategoriesResponse> =>
  await API.post(`/api/categories/${category.id}/localizations`, { ...category.attributes })

export const insertLocalizedTag = async (tag: any): Promise<StrapiTagsResponse> =>
  await API.post(`/api/tags/${tag.id}/localizations`, { ...tag.attributes })

export const insertLocalizedFundamentalAnalysis = async (article: any): Promise<StrapiFundamentalAnalysesResponse> =>
  await API.post(`/api/fundamental-analyses/${article.id}/localizations`, { ...article.attributes })

export const fetchAssetArticles = async (payload: any): Promise<StrapiAssetArticlesResponse> =>
  await API.get('/api/asset-articles', payload).catch(e => {
    console.error('Error fetching asset articles', e)
    return EMPTY_STRAPI_RESPONSE
  })

export const fetchCategories = async (payload: any = {}): Promise<StrapiCategoriesResponse> =>
  await API.get('/api/categories', { ...payload, sort: 'name:asc' }).catch(e => {
    console.error('Error fetching categories', e)
    return EMPTY_STRAPI_RESPONSE
  })

export const getCategories = async (): Promise<Category[]> =>
  (await fetchCategories({})).data.map(convertCategoryFromStrapiCategory)

export const fetchAssets = async (payload: any): Promise<StrapiAssetsResponse> =>
  await API.get('/api/assets', payload).catch(e => {
    console.error('Error fetching assets', e)
    return EMPTY_STRAPI_RESPONSE
  })

export const fetchAssetPages = async (payload: any): Promise<StrapiAssetPagesResponse> =>
  await API.get('/api/asset-pages', payload)

export const fetchRedirections = async (payload: any): Promise<StrapiRedirectionsResponse> =>
  await API.get('/api/redirections', payload)

export const fetchRedirectionsRegex = async (payload: any): Promise<StrapiRedirectionsRegexResponse> =>
  await API.get('/api/redirection-regexes', payload)

export const fetchTags = async (payload: any = {}): Promise<StrapiTagsResponse> =>
  await API.get('/api/tags', { ...payload, sort: 'name:asc' }).catch(e => {
    console.error('Error fetching tags', e)
    return EMPTY_STRAPI_RESPONSE
  })

export const fetchFundamentalAnalyses = async (payload: any): Promise<StrapiFundamentalAnalysesResponse> =>
  await API.get('/api/fundamental-analyses', payload).catch(e => {
    console.error('Error fetching fundamental analyses', e)
    return EMPTY_STRAPI_RESPONSE
  })

export const manageAsset = async (method: 'post' | 'put', url: string, body: any): Promise<StrapiAssetsUpsetResponse> =>
  await API[method](url, body)

export const deleteAsset = async (id: number | string) => await API.delete(`/api/assets/${id}`)

export const upsertAssets = async (assets: Asset[]) => {
  for (const asset of assets) {
    const { meta } = await fetchAssets({ filters: { code: { $eq: asset?.code } } })

    if (meta.pagination.total > 0) {
      await manageAsset('put', `/api/assets/${asset.id}`, { data: asset })
    } else {
      await manageAsset('post', '/api/assets', { data: asset })
    }
  }
}

export const deleteAssets = async (ids: (number | string)[]) => {
  const deletePromises = ids.map(deleteAsset)

  await Promise.all(deletePromises)
}

const insertCategory = async (payload: any): Promise<StrapiCategoriesResponse> =>
  await API.post('/api/categories', { data: payload })

export const updateCategory = async (id: number | string, category: any): Promise<StrapiCategoriesResponse> =>
  await API.put(`/api/categories/${id}`, { data: category })

export const upsertCategory = async (categories: any[]) => {
  for (const category of categories) {
    const existingCategories = await fetchCategories({ filters: { category_id: { $eq: category?.category_id } } })

    if (existingCategories.meta && existingCategories.meta.pagination.total > 0) {
      await updateCategory(category.id, category)
    } else {
      await insertCategory(category)
    }
  }
}

export const updateLocalizedArticle = async (article: StrapiArticle): Promise<StrapiArticlesResponse> => 
  await API.put(`/api/articles/${article.id}`, { data: article.attributes })

export const updateLocalizedAssetArticle = async (article: StrapiAssetArticle): Promise<StrapiAssetArticlesResponse> => 
  await API.put(`/api/asset-articles/${article.id}`, { data: article.attributes })

export const updateLocalizedAssetPage = async (article: any): Promise<StrapiAssetPagesResponse> => 
  await API.put(`/api/asset-pages/${article.id}`, { data: article.attributes })

export const updateLocalizedCategory = async (category: StrapiCategory): Promise<StrapiCategoriesResponse> => 
  await API.put(`/api/categories/${category.id}`, { data: category.attributes })

export const updateLocalizedTag = async (tag: StrapiTag): Promise<StrapiTagsResponse> => 
  await API.put(`/api/tags/${tag.id}`, { data: tag.attributes })

export const updateLocalizedFundamentalAnalysis = async (article: StrapiFundamentalAnalysis): Promise<StrapiFundamentalAnalysesResponse> => 
  await API.put(`/api/fundamental-analyses/${article.id}`, { data: article.attributes })

export const upsertLocalizedArticle = async (article: any) => {
  const existingArticles = await fetchArticles({ 
    filters: { id: { $eq: article.id } },
    populate: 'asset,localizations'
  })

  const existingLocalizedArticle = existingArticles.data[0].attributes.localizations?.data.find(l => l.attributes.locale === article.attributes.locale)
  const localizedItemId = existingLocalizedArticle?.id

  if (localizedItemId) {
    await updateLocalizedArticle({
      ...article, 
      id: localizedItemId,
      attributes: {
        ...article.attributes,
        asset: existingArticles.data[0].attributes.asset?.data?.id
      }
    })
  } else {
    await insertLocalizedArticle({
      ...article,
      attributes: {
        ...article.attributes,
        asset: existingArticles.data[0].attributes.asset?.data?.id
      }
    })
  }
}

export const upsertLocalizedAssetArticle = async (article: any) => {
  const existingArticles = await fetchAssetArticles({ 
    filters: { id: { $eq: article.id } },
    populate: 'asset,localizations'
  })

  const existingLocalizedArticle = existingArticles.data[0].attributes.localizations?.data.find(l => l.attributes.locale === article.attributes.locale)
  const localizedItemId = existingLocalizedArticle?.id

  if (localizedItemId) {
    await updateLocalizedAssetArticle({
      ...article, 
      id: localizedItemId, 
      attriburtes: {
        ...article.attributes,
        asset: existingArticles.data[0].attributes.asset?.data?.id
      }
    })
  } else {
    await insertLocalizedAssetArticle({
      ...article,
      attributes: {
        ...article.attributes,
        asset: existingArticles.data[0].attributes.asset?.data?.id
      }
    })
  }
}

export const upsertLocalizedAssetPage = async (article: StrapiAssetPage) => {
  const existingArticles = await fetchAssetPages({ 
    filters: { id: { $eq: article.id } },
    populate: 'asset,localizations'
  })

  const existingLocalizedArticle = existingArticles.data[0].attributes.localizations?.data.find(l => l.attributes.locale === article.attributes.locale)
  const localizedItemId = existingLocalizedArticle?.id

  if (localizedItemId) {
    await updateLocalizedAssetPage({
      ...article, 
      id: localizedItemId,
      attributes: {
        ...article.attributes,
      }
    })
  } else {
    await insertLocalizedAssetPage({
      ...article,
      attributes: {
        ...article.attributes,
      }
    })
  }
}

export const upsertLocalizedCategory = async (category: StrapiCategory) => {
  const existingCategories = await fetchCategories({ 
    filters: { id: { $eq: category.id } },
    populate: 'localizations'
  })

  const existingLocalizedCategory = existingCategories.data[0].attributes.localizations?.data.find(l => l.attributes.locale === category.attributes.locale)
  const localizedItemId = existingLocalizedCategory?.id

  if (localizedItemId) {
    await updateLocalizedCategory({...category, id: localizedItemId})
  } else {
    await insertLocalizedCategory(category)
  }
}

export const upsertLocalizedTag = async (tag: StrapiTag) => {
  const existingTags = await fetchTags({ 
    filters: { id: { $eq: tag.id } },
    populate: 'localizations'
  })

  const existingLocalizedTag = existingTags.data[0].attributes.localizations?.data.find(l => l.attributes.locale === tag.attributes.locale)
  const localizedItemId = existingLocalizedTag?.id

  if (localizedItemId) {
    await updateLocalizedTag({...tag, id: localizedItemId})
  } else {
    await insertLocalizedTag(tag)
  }
}

export const upsertLocalizedFundamentalAnalysis = async (article: any) => {
  const existingArticles = await fetchFundamentalAnalyses({ 
    filters: { id: { $eq: article.id } },
    populate: 'asset,localizations'
  })

  const existingLocalizedArticle = existingArticles.data[0].attributes.localizations?.data.find(l => l.attributes.locale === article.attributes.locale)
  const localizedItemId = existingLocalizedArticle?.id

  if (localizedItemId) {
    await updateLocalizedFundamentalAnalysis({
      ...article, 
      id: localizedItemId, 
      attriburtes: {
        ...article.attributes,
        asset: existingArticles.data[0].attributes.asset?.data?.id
      }
    })
  } else {
    await insertLocalizedFundamentalAnalysis({
      ...article,
      attributes: {
        ...article.attributes,
        asset: existingArticles.data[0].attributes.asset?.data?.id
      }
    })
  }
}
